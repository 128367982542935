/* eslint-disable @next/next/no-img-element */

'use client';

import { closeModalAtom } from '@core/Atoms/Modal/Modal.atom';
import Button from '@gds/Button/Button';
import { Car, Email, Search } from '@gds/Icons';
import { Typography } from '@gds/Typography/Typography';

import { activeAccModalAtom } from '@my-account/Atoms/AccModal.atom';
import { userProfileAtom } from '@my-account/Atoms/UserProfile.atom';
import loginIcon from '@my-account/Components/AccLoginForm/login.svg';

import { useApiData } from '@my-account/Utils/CreateApiDataAtom';
import { useAtomValue, useSetAtom } from 'jotai';

import { postSignupStringsAtom } from './PostSignupMessages.atom';

import styles from './PostSignup.module.css';

type PostSignupProps = {
  variant?: 'savedSearch';
};

export const PostSignup = ({ variant }: PostSignupProps) => {
  const setCurrentAccModal = useSetAtom(activeAccModalAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const { data: userProfile } = useApiData(userProfileAtom);

  const strings = useAtomValue(postSignupStringsAtom);

  const handleSignup = () => {
    closeModal();
    setCurrentAccModal('signup');
  };

  const keepTrackString =
    variant === 'savedSearch' ? strings.keepTrackSavedSearch : strings.keepTrack;

  if (userProfile) return null;

  return (
    <div className={styles.wrapper}>
      {/* Avatar icon */}
      <div className={styles.avatarWrapper}>
        <div className={styles.avatar}>
          <img src={loginIcon.src} />
        </div>
      </div>

      {/* Main heading */}
      <Typography variant="body3" className={styles.heading}>
        <strong>{keepTrackString}</strong>
      </Typography>

      {/* Features grid */}
      <div className={styles.features}>
        <div className={styles.feature}>
          <Car size={32} />
          <Typography variant="caption1">{strings.getRecommendations}</Typography>
        </div>

        <div className={styles.feature}>
          <Search size={32} />
          <Typography variant="caption1">{strings.viewSavedSearch}</Typography>
        </div>

        <div className={styles.feature}>
          <Email size={32} />
          <Typography variant="caption1">{strings.trackDealerEnquiries}</Typography>
        </div>
      </div>

      <Button size="large" onClick={handleSignup} dataTestId="post-lead-signup">
        {strings.accountSignup}
      </Button>
    </div>
  );
};
