const BASE_URL = '/api/border-control';

const USERS_BASE_URL = `${BASE_URL}/users`;
const AUTH_BASE_URL = `${BASE_URL}/auth`;

export const borderControlApiUrls = {
  users: {
    profile: `${USERS_BASE_URL}/profile`,
    leads: `${USERS_BASE_URL}/leads`,
    savedSearches: `${USERS_BASE_URL}/saved-searches`,
    dashboard: `${USERS_BASE_URL}/dashboard`,
  },
  auth: {
    changePassword: `${AUTH_BASE_URL}/change-password`,
    deleteAccount: `${AUTH_BASE_URL}/delete-account`,
  },
};
