import {
  QueryParams,
  FilterKey,
  FilterParams,
  UrlParams,
} from 'libs/core/src/Entities/Search/QueryParams.entity';

import { stringifyParams } from './queryParamUtils';
import { Tenant } from '@whitelabel/Entities/Tenants.entity';
import { SortTypes } from '@core/Entities/Search/Sort.entity';

import { tenantSpecialParams } from '@whitelabel/Filters/TenantsSpecialParams';

export const mapQueryParams = (params: UrlParams, tenant?: Tenant) => {
  const filterParams = {} as FilterParams;
  for (const key in params) {
    const newKey = key.replace('[]', '') as FilterKey;

    filterParams[newKey] =
      (tenant && tenantSpecialParams[tenant][params[key] as SortTypes]) ?? (params[key] as any);
  }

  let mappedParams: QueryParams | {} = {};
  mappedParams = {
    ...filterParams,
    ...{
      page:
        filterParams?.page && parseInt(filterParams?.page) > 0
          ? parseInt(filterParams?.page) - 1
          : filterParams.page || 0,
    },
  };
  const queryString = stringifyParams(mappedParams);

  return { mappedParams: mappedParams as FilterParams, queryString };
};
